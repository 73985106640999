/*************************************************************
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT  *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT  *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT  *
 *                                                            *
 * This was generated by `be/hrms_api/codes-sync.sh`.   *
 *                                                            *
 * Please edit `be/hrms_api/codes.txt` and run the sync *
 * script.                                                    *
 *                                                            *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT  *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT  *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT  *
 *************************************************************/

const hrmsApiHttpCodes = {
    // Reserved
    RC_E_NO_BODY: 2999,
    RC_E_MALFORMED: 2998,
    RC_E_RATELIMIT: 2997,
    RC_E_MCLOUD_ACCT_NOT_VERIFIED: 2996,
    RC_E_HRMS_DOES_NOT_EXISTS: 2995,
    RC_E_ROLE_DOES_NOT_EXISTS: 2994,
    RC_HRMS_ALREADY_EXISTS: 1000,
    RC_E_HRMS_INVALID_FIELD_TYPE: 2992,
    RC_E_HRMS_INVALID_OBJECT_TYPE: 2991,
    RC_E_HRMS_FIELD_NOT_LINKED_WITH_SCMS: 2990,
    RC_E_HRMS_INVALID_FIELD_CONSTRAINTS: 2989,
    RC_E_HRMS_INVALID_FIELD_CONSTRAINTS_ORDER: 2988,
    RC_E_HRMS_INVALID_FIELD_VALUE: 2987,
    RC_E_HRMS_FIELD_NAME_ALREADY_EXISTS: 2986,
    RC_E_HRMS_INVALID_FIELD_ID: 2985,

    // GET /_/hrms/v1/analytics
    RC_ANALYTICS: 1000,
    RC_E_ANALYTICS_NOT_FOUND: 2000,

    // GET /_/hrms/v1/settings
    RC_SETTINGS_DETAILS: 1000,
    RC_E_SETTINGS_DETAILS_NOT_FOUND: 2000,

    // GET /_/hrms/v1/role/list
    RC_HRMS_ROLE_LIST: 1000,

    // GET /_/hrms/v1/role
    RC_HRMS_ROLE_DETAILS: 1000,

    // DELETE /_/hrms/v1/role
    RC_HRMS_ROLE_DELETE: 1000,

    // POST /_/hrms/v1/role/update
    RC_HRMS_ROLE_UPDATE: 1000,

    // POST /_/hrms/v1/role
    RC_HRMS_ROLE_CREATE: 1000,
    RC_E_HRMS_ROLE_CREATE_ALREADY_EXISTS: 2000,

    // GET /_/hrms/v1/company/list
    RC_COMPANY_LIST: 1000,
    RC_E_COMPANY_LIST_NOT_FOUND: 2000,

    // GET /_/hrms/v1/company/location
    RC_COMPANY_LOCATION_DETAILS: 1000,
    RC_E_COMPANY_LOCATION_DETAILS_NOT_FOUND: 2000,

    // GET /_/hrms/v1/company/location/list
    RC_COMPANY_LOCATION_LIST: 1000,
    RC_E_COMPANY_LOCATION_LIST_NOT_FOUND: 2000,

    // POST /_/hrms/v1/company
    RC_COMPANY_CREATE: 1000,
    RC_COMPANY_CREATE_HRMS_ALREADY_EXISTS: 1001,
    RC_E_COMPANY_CREATE_DUPLICATE: 2000,
    RC_E_COMPANY_URL_NOT_VALID: 2001,
    RC_E_COMPANY_PHONE_NOT_VALID: 2002,

    // POST /_/hrms/v1/settings/update
    RC_SETTINGS_UPDATE: 1000,
    RC_E_SETTINGS_UPDATE_NOT_FOUND: 2000,

    // POST /_/hrms/v1/company/location
    RC_COMPANY_LOCATION_CREATE: 1000,
    RC_E_COMPANY_LOCATION_CREATE_DUPLICATE: 2000,

    // POST /_/hrms/v1/company/location/update
    RC_COMPANY_LOCATION_UPDATE: 1000,
    RC_E_COMPANY_LOCATION_UPDATE_NOT_FOUND: 2000,

    // DELETE /_/hrms/v1/company
    RC_COMPANY_DELETE: 1000,
    RC_E_COMPANY_DELETE_NOT_FOUND: 2000,

    // DELETE /_/hrms/v1/company/location
    RC_COMPANY_LOCATION_DELETE: 1000,
    RC_E_COMPANY_LOCATION_DELETE_NOT_FOUND: 2000,

    // GET /_/hrms/v1/dept
    RC_DEPT_DETAILS: 1000,
    RC_E_DEPT_DETAILS_NOT_FOUND: 2000,

    // GET /_/hrms/v1/dept/list
    RC_DEPT_LIST: 1000,
    RC_E_DEPT_LIST_NOT_FOUND: 2000,

    // POST /_/hrms/v1/dept
    RC_DEPT_CREATE: 1000,
    RC_E_DEPT_CREATE_DUPLICATE: 2000,

    // POST /_/hrms/v1/dept/update
    RC_DEPT_UPDATE: 1000,
    RC_E_DEPT_UPDATE_NOT_FOUND: 2000,

    // DELETE /_/hrms/v1/dept
    RC_DEPT_DELETE: 1000,

    // GET /_/hrms/v1/designation
    RC_DESIGNATION_DETAILS: 1000,
    RC_E_DESIGNATION_DETAILS_NOT_FOUND: 2000,

    // GET /_/hrms/v1/designation/list
    RC_DESIGNATION_LIST: 1000,
    RC_E_DESIGNATION_LIST_NOT_FOUND: 2000,

    // POST /_/hrms/v1/designation
    RC_DESIGNATION_CREATE: 1000,
    RC_E_DESIGNATION_CREATE_DUPLICATE: 2000,

    // POST /_/hrms/v1/designation/update
    RC_DESIGNATION_UPDATE: 1000,
    RC_E_DESIGNATION_UPDATE_NOT_FOUND: 2000,

    // DELETE /_/hrms/v1/designation
    RC_DESIGNATION_DELETE: 1000,
    RC_E_DESIGNATION_DELETE_NOT_FOUND: 2000,

    // GET /_/hrms/v1/emp
    RC_EMP_DETAILS: 1000,
    RC_E_EMP_DETAILS_NOT_FOUND: 2000,

    // GET /_/hrms/v1/emp/list
    RC_EMP_LIST: 1000,
    RC_E_EMP_LIST_NOT_FOUND: 2000,

    // POST /_/hrms/v1/emp/designation/update
    RC_EMP_DESIGNATION_UPDATE: 1000,
    RC_E_EMP_DESIGNATION_UPDATE__DUPLICATE: 2000,

    // POST /_/hrms/v1/emp/edu
    RC_EMP_EDU_CREATE: 1000,
    RC_E_EMP_EDU_CREATE_DUPLICATE: 2000,

    // POST /_/hrms/v1/emp/edu/update
    RC_EMP_EDU_UPDATE: 1000,
    RC_E_EMP_EDU_UPDATE_NOT_FOUND: 2000,

    // POST /_/hrms/v1/emp/exp
    RC_EMP_EXP_CREATE: 1000,
    RC_E_EMP_EXP_CREATE_DUPLICATE: 2000,

    // POST /_/hrms/v1/emp/exp/update
    RC_EMP_EXP_UPDATE: 1000,
    RC_E_EMP_EXP_UPDATE_NOT_FOUND: 2000,

    // POST /_/hrms/v1/emp/document_upload
    RC_EMP_DOCUMENT_UPLOAD: 1000,

    // POST /_/hrms/v1/emp/set_profile_img
    RC_EMP_SET_PROFILE_IMG: 1000,

    // POST /_/hrms/v1/emp
    RC_EMP_CREATE: 1000,
    RC_E_EMP_CREATE_DUPLICATE: 2000,
    RC_E_EMP_PHONE_NOT_VALID: 2001,
    RC_E_EMP_PERSONAL_EMAIL_DUPLICATE: 2002,
    RC_E_EMP_WORK_EMAIL_DUPLICATE: 2003,
    RC_E_EMP_USAGE_LIMIT_REACHED: 2004,

    // POST /_/hrms/v1/emp/update
    RC_EMP_UPDATE: 1000,
    RC_EMP_UPDATE_NO_CHANGE: 1001,
    RC_E_EMP_UPDATE_NOT_FOUND: 2000,

    // DELETE /_/hrms/v1/emp
    RC_EMP_DELETE: 1000,
    RC_E_EMP_DELETE_NOT_FOUND: 2000,

    // DELETE /_/hrms/v1/emp/edu
    RC_EMP_EDU_DELETE: 1000,
    RC_E_EMP_EDU_DELETE_NOT_FOUND: 2000,

    // POST /_/hrms/v1/init
    RC_HRMS_INIT: 1000,

    // POST /_/hrms/v1/emp/offboarding
    RC_EMP_OFFBOARDING_CREATE: 1000,
    RC_E_EMP_OFFBOARDING_CREATE_DUPLICATE: 2000,
    RC_E_EMP_OFFBOARDING_CREATE_NO_ASSIGNEE: 2001,

    // POST /_/hrms/v1/emp/onboarding
    RC_EMP_ONBOARDING_CREATE: 1000,
    RC_E_EMP_ONBOARDING_CREATE_DUPLICATE: 2000,
    RC_E_EMP_ONBOARDING_CREATE_NO_ASSIGNEE: 2001,

    // GET /_/hrms/v1/org
    RC_ORG_DETAILS: 1000,
    RC_E_ORG_DETAILS_NOT_FOUND: 2000,

    // GET /_/hrms/v1/org/list
    RC_ORG_LIST: 1000,
    RC_E_ORG_LIST_NOT_FOUND: 2000,

    // POST /_/hrms/v1/org
    RC_ORG_CREATE: 1000,
    RC_E_ORG_CREATE_DUPLICATE: 2000,
    RC_E_ORG_URL_NOT_VALID: 2001,
    RC_E_ORG_PHONE_NOT_VALID: 2002,

    // POST /_/hrms/v1/org/update
    RC_ORG_UPDATE: 1000,
    RC_E_ORG_UPDATE_NOT_FOUND: 2000,

    // DELETE /_/hrms/v1/org
    RC_ORG_DELETE: 1000,
    RC_E_ORG_DELETE_NOT_FOUND: 2000,

    // GET /_/hrms/v1/task
    RC_TASK_DETAILS: 1000,
    RC_E_TASK_DETAILS_NOT_FOUND: 2000,

    // GET /_/hrms/v1/task/list
    RC_TASK_LIST: 1000,
    RC_E_TASK_LIST_NOT_FOUND: 2000,

    // POST /_/hrms/v1/task
    RC_TASK_CREATE: 1000,
    RC_E_TASK_CREATE_DUPLICATE: 2000,

    // POST /_/hrms/v1/task/complete
    RC_TASK_COMPLETE: 1000,
    RC_TASK_COMPLETED: 1001,

    // POST /_/hrms/v1/task/update
    RC_TASK_UPDATE: 1000,
    RC_E_TASK_UPDATE_NOT_FOUND: 2000,

    // DELETE /_/hrms/v1/task
    RC_TASK_DELETE: 1000,
    RC_E_TASK_DELETE_NOT_FOUND: 2000,

    // GET /_/hrms/v1/team
    RC_TEAM_DETAILS: 1000,
    RC_E_TEAM_DETAILS_NOT_FOUND: 2000,

    // GET /_/hrms/v1/team/list
    RC_TEAM_LIST: 1000,
    RC_E_TEAM_LIST_NOT_FOUND: 2000,

    // POST /_/hrms/v1/team
    RC_TEAM_CREATE: 1000,
    RC_E_TEAM_CREATE_DUPLICATE: 2000,

    // POST /_/hrms/v1/team/update
    RC_TEAM_UPDATE: 1000,
    RC_E_TEAM_UPDATE_NOT_FOUND: 2000,

    // DELETE /_/hrms/v1/team
    RC_TEAM_DELETE: 1000,
    RC_E_TEAM_DELETE_NOT_FOUND: 2000,

    // GET /_/hrms/v1/task_tmpl
    RC_TASK_TMPL_DETAILS: 1000,
    RC_E_TASK_TMPL_DETAILS_NOT_FOUND: 2000,

    // GET /_/hrms/v1/task_tmpl/list
    RC_TASK_TMPL_LIST: 1000,
    RC_E_TASK_TMPL_LIST_NOT_FOUND: 2000,

    // POST /_/hrms/v1/task_tmpl
    RC_TASK_TMPL_CREATE: 1000,
    RC_E_TASK_TMPL_CREATE_DUPLICATE: 2000,

    // POST /_/hrms/v1/task_tmpl/update
    RC_TASK_TMPL_UPDATE: 1000,
    RC_E_TASK_TMPL_UPDATE_NOT_FOUND: 2000,

    // DELETE /_/hrms/v1/task_tmpl
    RC_TASK_TMPL_DELETE: 1000,
    RC_E_TASK_TMPL_DELETE_NOT_FOUND: 2000,

    // GET /_/hrms/v1/emp/profile_update_req
    RC_EMP_PROFILE_UPDATE_REQ_DETAILS: 1000,
    RC_E_EMP_PROFILE_UPDATE_REQ_DETAILS_NOT_FOUND: 2000,

    // POST /_/hrms/v1/emp/profile_update_req_reject
    RC_EMP_PROFILE_UPDATE_REQ_REJECTED: 1000,
    RC_E_EMP_PROFILE_UPDATE_REQ_NOT_FOUND: 2000,

    // POST /_/hrms/v1/emp/profile_update_req_approve
    RC_EMP_PROFILE_UPDATE_REQ_APPROVED: 1000,

    // GET /_/hrms/v1/emp/profile_update_req/list
    RC_EMP_PROFILE_UPDATE_REQ_LIST: 1000,
    RC_E_EMP_PROFILE_UPDATE_REQ_LIST_NOT_FOUND: 2000,

    // POST /_/hrms/v1/document
    RC_DOCUMENT_CREATE: 1000,

    // DELETE /_/hrms/v1/document
    RC_DOCUMENT_DELETE: 1000,
    RC_E_DOCUMENT_DELETE_NOT_FOUND: 2000,

    // GET /_/hrms/v1/document/list
    RC_DOCUMENT_LIST: 1000,

    // GET /_/hrms/v1/auditlog/list
    RC_HRMS_AUDITLOG_LIST: 1000,

    // POST /_/hrms/v1/invite_accept
    RC_HRMS_INVITE_ACCEPT: 1000,
    RC_E_HRMS_INVITE_ACCEPT_INVITE_NOT_EXISTS: 2000,
    RC_E_MCLOUD_HRMS_INVITE_ACCEPT_USER_NOT_EXISTS: 2001,

    // POST /_/hrms/v1/invite_reject
    RC_HRMS_INVITE_REJECT: 1000,
    RC_E_HRMS_INVITE_REJECT_INVITE_NOT_EXISTS: 2000,

    // GET /_/hrms/v1/dashboard/feed/list
    RC_DASHBOARD_FEED_LIST: 1000,
    RC_E_DASHBOARD_FEED_LIST_NOT_FOUND: 2000,

    // GET /_/hrms/v1/field
    RC_HRMS_FIELD: 1000,
    RC_E_HRMS_FIELD_INVALID_FIELD_ID: 2000,

    // GET /_/hrms/v1/field/list
    RC_HRMS_FIELD_List: 1000,

    // GET /_/hrms/v1/obj/field/list"
    RC_HRMS_OBJ_FIELD_LIST: 1000,

    // POST /_/hrms/v1/field
    RC_HRMS_FIELD_CREATE: 1000,

    // POST /_/hrms/v1/field/update
    RC_HRMS_FIELD_UPDATE: 1000,

    // POST /_/hrms/v1/field/attach
    RC_HRMS_FIELDS_ATTACH: 1000,

    // DELETE /_/hrms/v1/field
    RC_HRMS_FIELDS_DELETE: 1000,

    // GET /_/hrms/v1/user_role
    RC_USER_ROLE: 1000,
    RC_E_USER_ROLE_NOT_EXISTS: 2000,

    // GET /_/hrms/v1/attendance/check_in
    RC_ATTENDANCE_CHECK_IN: 1000,
    RC_E_ATTENDANCE_CHECK_IN_ALREADY_CHECKED_IN: 2000,
    RC_E_ATTENDANCE_CHECK_IN_CANNOT_CHECK_IN_AGAIN: 2001,

    // GET /_/hrms/v1/attendance/check_out
    RC_ATTENDANCE_CHECK_OUT: 1000,
    RC_E_ATTENDANCE_CHECK_OUT_ALREADY_CHECKED_OUT: 2000,
    RC_E_ATTENDANCE_CHECK_OUT_NO_CHECK_IN: 2001,

    // GET /_/hrms/v1/attendance/current_status
    RC_ATTENDANCE_CURRENT_STATUS: 1000,

    // GET /_/hrms/v1/attendance
    RC_ATTENDANCE_DETAILS: 1000,
    RC_E_ATTENDANCE_DETAILS_NOT_FOUND: 2000,

    // POST /_/hrms/v1/attendance/leave_manual/create
    RC_ATTENDANCE_LEAVE_MANUAL_CREATE: 1000,

    // DELETE /_/hrms/v1/attendance/leave_manual/delete
    RC_ATTENDANCE_LEAVE_MANUAL_DELETE: 1000,

    // GET /_/hrms/v1/attendance/leave_manual/details
    RC_ATTENDANCE_LEAVE_MANUAL_DETAILS: 1000,
    RC_E_ATTENDANCE_LEAVE_MANUAL_DETAILS_NOT_FOUND: 2000,

    // POST /_/hrms/v1/attendance/leave_manual/update
    RC_ATTENDANCE_LEAVE_MANUAL_UPDATE: 1000,
    RC_E_ATTENDANCE_LEAVE_MANUAL_UPDATE_NOT_FOUND: 2000,

    // GET /_/hrms/v1/attendance/leave_manual/list
    RC_ATTENDANCE_LEAVE_MANUAL_LIST: 1000,

    // POST /_/hrms/v1/attendance/leave_req/approve
    RC_ATTENDANCE_LEAVE_REQ_APPROVE: 1000,
    RC_E_ATTENDANCE_LEAVE_REQ_APPROVE_NOT_FOUND: 2000,
    RC_E_ATTENDANCE_LEAVE_REQ_APPROVE_LIMIT_REACHED: 2001,

    // POST /_/hrms/v1/attendance/leave_req/create
    RC_ATTENDANCE_LEAVE_REQ_CREATE: 1000,

    // GET /_/hrms/v1/attendance/leave_req
    RC_ATTENDANCE_LEAVE_REQ_DETAILS: 1000,
    RC_E_ATTENDANCE_LEAVE_REQ_DETAILS_NOT_FOUND: 2000,

    // GET /_/hrms/v1/attendance/leave_req/list
    RC_ATTENDANCE_LEAVE_REQ_LIST: 1000,

    // POST /_/hrms/v1/attendance/leave_req/reject
    RC_ATTENDANCE_LEAVE_REQ_REJECT: 1000,
    RC_E_ATTENDANCE_LEAVE_REQ_REJECT_NOT_FOUND: 2000,

    // GET /_/hrms/v1/attendance/list
    RC_ATTENDANCE_LIST: 1000,
    RC_E_ATTENDANCE_LIST_NOT_FOUND: 2000,

    // GET /_/hrms/v1/attendance/pause
    RC_ATTENDANCE_PAUSE: 1000,
    RC_E_ATTENDANCE_PAUSE_ALREADY_PAUSED: 2000,
    RC_E_ATTENDANCE_PAUSE_NO_CHECK_IN: 2001,

    // POST /_/hrms/v1/attendance/policy/create
    RC_ATTENDANCE_POLICY_CREATE: 1000,

    // DELETE /_/hrms/v1/attendance/policy/delete
    RC_ATTENDANCE_POLICY_DELETE: 1000,
    RC_E_ATTENDANCE_POLICY_DELETE_NOT_FOUND: 2000,

    // GET /_/hrms/v1/attendance/policy
    RC_ATTENDANCE_POLICY_DETAILS: 1000,
    RC_E_ATTENDANCE_POLICY_DETAILS_NOT_FOUND: 2000,

    // GET /_/hrms/v1/attendance/policy/list
    RC_ATTENDANCE_POLICY_LIST: 1000,

    // POST /_/hrms/v1/attendance/policy/update
    RC_ATTENDANCE_POLICY_UPDATE: 1000,
    RC_E_ATTENDANCE_POLICY_UPDATE_NOT_FOUND: 2000,

    // GET /_/hrms/v1/attendance/resume
    RC_ATTENDANCE_RESUME: 1000,
    RC_E_ATTENDANCE_RESUME_ALREADY_RESUMED: 2000,
    RC_E_ATTENDANCE_RESUME_NO_CHECK_IN: 2001,
    RC_E_ATTENDANCE_RESUME_NOT_PAUSED: 2002,

    // POST /_/hrms/v1/attendance/update
    RC_ATTENDANCE_UPDATE: 1000,
    RC_E_ATTENDANCE_UPDATE_NOT_FOUND: 2000,

    // GET /_/hrms/v1/attendance/update_req/approve
    RC_ATTENDANCE_UPDATE_REQ_APPROVE: 1000,
    RC_E_ATTENDANCE_UPDATE_REQ_APPROVE_NOT_FOUND: 2000,

    // POST /_/hrms/v1/attendance/update_req/create
    RC_ATTENDANCE_UPDATE_REQ_CREATE: 1000,

    // GET /_/hrms/v1/attendance/update_req
    RC_ATTENDANCE_UPDATE_REQ_DETAILS: 1000,
    RC_E_ATTENDANCE_UPDATE_REQ_DETAILS_NOT_FOUND: 2000,

    // GET /_/hrms/v1/attendance/update_req/list
    RC_ATTENDANCE_UPDATE_REQ_LIST: 1000,

    // GET /_/hrms/v1/attendance/update_req/reject
    RC_ATTENDANCE_UPDATE_REQ_REJECT: 1000,
    RC_E_ATTENDANCE_UPDATE_REQ_REJECT_NOT_FOUND: 2000,

    // GET /_/hrms/v1/attendance/req/list
    RC_REQ_LIST: 1000,

    // GET /_/hrms/v1/attendace/leave_details
    RC_ATTENDANCE_LEAVE_GRAPH: 1000,

    // GET /_/hrms/v1/dashboard/event/list
    RC_DASHBOARD_EVENT_LIST: 1000,
    RC_E_DASHBOARD_EVENT_LIST_NOT_FOUND: 2000,
};

declare global {
    var hrmsHttpCodes: typeof hrmsApiHttpCodes;
}

globalThis.hrmsHttpCodes = hrmsApiHttpCodes;

export default hrmsHttpCodes;

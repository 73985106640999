/***************************************************************
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT   *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT   *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT   *
 *                                                             *
 * This was generated by `be/scms_api/codes-sync.sh`.          *
 *                                                             *
 * Please edit `be/scms_api/codes.txt` and run the sync script.*
 *                                                             *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT   *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT   *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT   *
 ***************************************************************/

const scmsApiHttpCodes = {
    // Reserved
    RC_E_NO_BODY: 2999,
    RC_E_MALFORMED: 2998,
    RC_E_RATELIMIT: 2997,
    RC_E_MCLOUD_ACCT_NOT_VERIFIED: 2996,
    RC_E_SCMS_NOT_EXISTS: 2995,
    RC_E_SCMS_INVALID_ASSET_ID: 2994,
    RC_E_SCMS_INVALID_TRANSACTION_ID: 2993,
    RC_E_SCMS_INVALID_FIELD_TYPE: 2992,
    RC_E_SCMS_INVALID_OBJECT_TYPE: 2991,
    RC_E_SCMS_FIELD_NOT_LINKED_WITH_SCMS: 2990,
    RC_E_SCMS_INVALID_FIELD_CONSTRAINTS: 2989,
    RC_E_SCMS_INVALID_FIELD_CONSTRAINTS_ORDER: 2988,
    RC_E_SCMS_INVALID_FIELD_VALUE: 2987,
    RC_E_SCMS_FIELD_NAME_ALREADY_EXISTS: 2986,
    RC_E_SCMS_INVALID_FIELD_ID: 2985,

    // POST /_/scms/init
    RC_SCMS_INIT: 1000,
    RC_E_SCMS_INIT_SCMS_ALREADY_EXISTS: 2000,

    // GET /_/scms/stats
    RC_SCMS_STATS: 1000,

    // POST /_/scms/asset/add
    RC_SCMS_ASSET_ADD: 1000,
    RC_E_ASSET_USAGE_LIMIT_REACHED: 2000,

    // GET /_/scms/asset/list
    RC_SCMS_ASSET_LIST: 1000,

    // GET /_/scms/asset/get
    RC_SCMS_ASSET_GET: 1000,

    // POST /_/scms/asset/upd
    RC_SCMS_ASSET_UPD: 1000,

    // DELETE /_/scms/assets/del
    RC_SCMS_ASSETS_DELETE: 1000,

    // POST /_/scms/asset/transaction/create
    RC_SCMS_ASSET_TRANSACTION_CREATE: 1000,
    RC_E_SCMS_ASSET_TRANSACTION_CREATE_INVALID_ASSIGNEE_ID: 2000,

    // GET /_/scms/asset/transaction/list
    RC_SCMS_ASSET_TRANSACTION_LIST: 1000,

    // POST /_/scms/field/create
    RC_SCMS_FIELD_CREATE: 1000,

    // GET /_/scms/field/get
    RC_SCMS_FIELD_GET: 1000,

    // POST /_/scms/field/upd
    RC_SCMS_FIELD_UPDATE: 1000,

    // GET /_/scms/field/list
    RC_SCMS_FIELD_List: 1000,

    // DELETE /_/scms/field/del
    RC_SCMS_FIELDS_DELETE: 1000,

    // POST /_/scms/fields_attach
    RC_SCMS_FIELDS_ATTACH: 1000,

    // GET /_/scms/objects_field_list
    RC_SCMS_OBJ_FIELD_LIST: 1000,

    // GET /_/api/scms/v1/auditlog/list
    RC_SCMS_AUDITLOG_LIST: 1000,
};

declare global {
    var scmsHttpCodes: typeof scmsApiHttpCodes;
}

globalThis.scmsHttpCodes = scmsApiHttpCodes;

export default scmsHttpCodes;
